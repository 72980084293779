/*=============================================
=            coming soon nine            =
=============================================*/

/* content */
.cs-09-content {
    &__title {
        font-family: $libre_Baskerville;
        font-weight: 700;
        font-size: 72px;
        line-height: 1.3;

        color: $blue--two;

        @media #{$large-desktop} {
            font-size: 50px;
        }
        @media #{$small-desktop,
        $extra-large-mobile,
        $large-mobile} {
            font-size: 40px;
        }
        @media #{$small-mobile} {
            font-size: 30px;
        }

        @media #{$extra-small-mobile} {
            font-size: 24px;
        }
    }
}

/* countdown */
.cs-09-countdown {
    font-family: $libre_Baskerville;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    text-align: center;

    @media #{$small-mobile} {
        margin-bottom: -30px;
    }
    .single-countdown {
        flex-basis: 100px;

        margin-right: 30px;
        @media #{$small-desktop,
        $extra-large-mobile,
        $large-mobile} {
            flex-basis: 90px;
        }

        @media #{$extra-small-mobile} {
            flex-basis: 70px;
        }

        @media #{$small-mobile} {
            margin-right: 15px;
            margin-bottom: 30px;
        }

        &:last-child {
            margin-right: 0;
        }
        &__time {
            font-size: 48px;
            font-weight: 400;

            display: block;

            margin-bottom: 15px;
            padding: 26px 0;

            color: $blue--two;
            border-radius: 10px;
            background-color: $white;

            @media #{$small-desktop,
            $extra-large-mobile,
            $large-mobile} {
                font-size: 35px;
            }

            @media #{$small-mobile} {
                font-size: 35px;
            }

            @media #{$extra-small-mobile} {
                font-size: 25px;
            }
        }
        &__text {
            font-size: 18px;

            color: $blue--two;

            @media #{$extra-small-mobile} {
                font-size: 16px;
            }
        }
    }
}

/* subscription */
.cs-09-subscription-wrapper {
    .mc-newsletter-form {
        input[type="email"] {
            font-family: $libre_Baskerville;
            font-size: 16px;
            font-weight: 400;
            width: 500px;
            max-width: 100%;
            margin-right: 5px;
            padding: 25px 30px;

            color: $blue--fourteen;
            border: 1px solid $white;
            border-radius: 10px;
            background-color: white;

            @media #{$small-desktop,
            $extra-large-mobile} {
                width: 300px;
                padding: 20px 30px;
            }

            @media #{$large-mobile} {
                margin-bottom: 15px;
                padding: 20px 30px;
            }
        }

        button {
            font-family: $libre_Baskerville;
            font-size: 18px;
            font-weight: 400;
            padding: 25px 65px;

            color: $white;
            border: none;
            border-radius: 10px;
            background: none;
            background-color: $blue--nine;

            @media #{$small-desktop,
            $extra-large-mobile,
            $large-mobile} {
                font-size: 16px;

                padding: 22px 40px;
            }

            &:hover {
                background-color: darken($color: $blue--nine, $amount: 10);
            }
        }
        ::placeholder {
            opacity: 1;

/* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $blue--fourteen;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $blue--fourteen;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $blue--fourteen;
        }
    }

    .mailchimp-alerts {
        font-family: $libre_Baskerville;
        line-height: 1.3;
        font-weight: 400;
        color: $blue--two;
        a {
            &:hover {
                color: $blue--nine;
            }
        }
    }
}

/* social icon*/
.cs-09-social-icons {
    & > li {
        margin-right: 20px;
        & > a {
            position: relative;

            width: 50px;
            height: 50px;

            border: 1px solid $white;
            border-radius: 50%;
            background-color: transparent;
            svg {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);
                * {
                    transition: $transition--default;
                }
            }

            &:hover {
                border-color: $blue--nine;
                background-color: $blue--nine;
                svg {
                    * {
                        fill: $white;
                    }
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }

    @media #{$large-mobile} {
        margin-top: 15px;
    }
}

/* copyright text */
.cs-09-copyright {
    font-family: $roboto;

    color: $blue--two;

    @media #{$large-mobile} {
        text-align: center;
    }

    a {
        &:hover {
            color: $blue--nine;
        }
    }
}

/*=====  End of coming soon nine  ======*/
