
/*=============================================
=            Common            =
=============================================*/

.bg-img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.h-100 {
    min-height: 100%;
}


/*=====  End of Common  ======*/

